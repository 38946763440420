import { Entity } from '@jobilla/entity';

export enum PaymentType {
  REGULAR = 'ALIAS_REGULAR', // regular transfer with question and answer
  AUTODEPOSIT = 'ALIAS_AUTODEPOSIT', // has auto-deposit set up; no q&a needed
}

export class WorkerInteracOptions extends Entity {
  public paymentType: PaymentType = null;
  public maxOutgoingAmount: number = null;
}
