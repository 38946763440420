import { UserRole } from '@entities/users/user-role.enum';
import { User } from '@entities/users/user.entity';
import { EntityBuilder } from '@jobilla/entity';
export class Worker extends User {
  public override roleId: UserRole = UserRole.Worker;
  public constructor(initData?: Partial<Worker>) {
    super();
    EntityBuilder.fill(this, initData as any);
  }
}
