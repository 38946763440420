import { Entity } from '@jobilla/entity';

export class WorkerRemittance extends Entity {
  public id: number = null;
  public workerId: number = null;
  public companyId: number = null;
  public year: number = null;
  public ytdEarnings: number = 0;
  public ytdEi: number = 0;
  public ytdCpp: number = 0;
  public ytdCpp2: number = 0;
  public ytdQpp: number = 0;
  public ytdQpp2: number = 0;
  public ytdQpip: number = 0;
  public ytdFedTax: number = 0;
  public ytdProvTax: number = 0;
  public employerYtdEi: number = 0;
  public employerYtdCpp: number = 0;
  public employerYtdCpp2: number = 0;
  public employerYtdQpp: number = 0;
  public employerYtdQpp2: number = 0;
  public employerYtdQpip: number = 0;
  public ytdTime: number = 0;
  public ytdAddedHst: number = 0;
  public ytdAdjustments: number = 0;
  public createdAt: string = null;
  public updatedBy: string = null;
  public updatedAt: number = null;
}
