import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';
import { Worker } from '@entities/workers/worker.entity';
import { Pager } from '@entities/pagers/pager.class';
import { ApiService } from '@services/api/api.service';
import { entity } from '@core/utils/rxjs';
import { QueryFilters } from '@entities/shared/query-filters.class';
import { WorkerExpenseSummary } from '@entities/workers/worker-expense-summary.entity';
import { WorkerRemittance } from '@entities/workers/worker-remittance.entity';
import { WorkerNextPayDate } from '@entities/workers/worker-next-pay-date.entity';
import { WorkerInteracOptions } from '@entities/workers/worker-interac-options';

@Injectable({
  providedIn: 'root',
})
export class WorkerService {
  private _currentWorker$ = new BehaviorSubject(null);
  public currentWorker$ = this._currentWorker$.asObservable();
  public constructor(private api: ApiService) {}

  public retrieveAll(pager: Pager<Worker>, filters?: QueryFilters): Observable<Pager<Worker>> {
    return this.api
      .getPagedEntities<Worker>({
        entityClass: Worker,
        path: 'workers',
        filters,
        pager,
      })
      .pipe(shareReplay());
  }

  public retrieveById(id: number): Observable<Worker> {
    return this.api.get<Worker>('workers/' + id).pipe(
      entity<Worker>(Worker),
      tap((worker) => {
        this._currentWorker$.next(worker);
      }),
      shareReplay()
    );
  }

  public clearCurrentWorker(): void {
    this._currentWorker$.next(null);
  }

  public retrieveExpenseSummary(workerId: number): Observable<WorkerExpenseSummary> {
    return this.api
      .get<WorkerExpenseSummary>('workers/' + workerId + '/expenses/summary')
      .pipe(entity<WorkerExpenseSummary>(WorkerExpenseSummary), shareReplay());
  }

  public create(worker: Worker): Observable<Worker> {
    return this.api.post('workers', worker).pipe(entity<Worker>(Worker), shareReplay());
  }

  public update(worker: Worker): Observable<Worker> {
    return this.api.put('workers/' + worker.id, worker).pipe(entity<Worker>(Worker), shareReplay());
  }

  public retrieveRemittances(
    workerId: number,
    pager: Pager<WorkerRemittance>,
    filters?: QueryFilters
  ): Observable<Pager<WorkerRemittance>> {
    return this.api
      .getPagedEntities({ path: 'workers/' + workerId + '/remittances', entityClass: WorkerRemittance, pager, filters })
      .pipe(shareReplay());
  }

  public createRemittance(workerRemittance: WorkerRemittance): Observable<WorkerRemittance> {
    return this.api
      .post('workers/' + workerRemittance.workerId + '/remittances', workerRemittance)
      .pipe(entity<WorkerRemittance>(WorkerRemittance), shareReplay());
  }

  public retrieveNextPayDate(workerId: number): Observable<WorkerNextPayDate> {
    return this.api
      .get('workers/' + workerId + '/next-pay-date')
      .pipe(entity<WorkerNextPayDate>(WorkerNextPayDate), shareReplay());
  }

  public getInteracPaymentOptions(workerId: number): Observable<WorkerInteracOptions> {
    return this.api
      .get('workers/' + workerId + '/interac-payment-option')
      .pipe(entity<WorkerInteracOptions>(WorkerInteracOptions), shareReplay());
  }
}
